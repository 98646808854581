import React from 'react';

import Image from './Image'
import Box from './Box'
import Flex from './Flex'
import { responsive } from './ThemeProvider/theme';

const ImageGallery = ({ images, ...props }) => {
  if (!images || !images.length) return null
  return (
    <Flex flexWrap="wrap" mx="-1em" {...props}>
      {images.map((src, i)=> (
        <Box width={responsive(1, 1 / 2)} key={i} mx="1em">
          <Image src={src} alt={src} />
        </Box>
      ))}
    </Flex>
  );
}

export default ImageGallery;
