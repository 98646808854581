import React from 'react'
import { MdPlayArrow } from 'react-icons/md'
import range from 'lodash/range'
import URLSearchParams from '@ungap/url-search-params'
import { Redirect } from '@reach/router'

import Box from 'components/Box'
import Flex from 'components/Flex'
import Link from 'components/Link'
import Text from 'components/Text'
import Image from 'components/Image'
import Button from 'components/Button'
import ImageGallery from 'components/ImageGallery'
import speaker from 'components/NewsLayout/speaker.png'
import ContentLayout from 'components/ContentLayout'
import { responsive } from 'components/ThemeProvider/theme';

import useResponsive from 'contexts/mediaQuery/useResponsive'
import Data from 'contexts/data'

import deco from './deco.png'

const imgKeys = range(5).map(i => `itemPicture${i + 1}`)

const SingleNews = ({ location }) => {
  const { isMobile } = useResponsive()
  const params = new URLSearchParams(location.search)
  return (
    <Data path={`/news/${params.get('id')}`}>
      {(data, loading) => !loading && (
        data ? (
          <ContentLayout title="公告訊息">
            <Flex alignItems="center">
              <Box as={(p) => <Link color="black" {...p} />} to="/">首頁</Box>
              <Box mx="0.5em" mb="0.125em"><MdPlayArrow /></Box>
              <Box as={(p) => <Link color="black" {...p} />} to="/news" fontWeight="bold" color="darkPurple">公告訊息</Box>
            </Flex>
            <Flex alignItems="center" pt="1.875em">
              <Box width="3.75em" mr="1.375em">
                <Image src={speaker} alt="一個大聲公的Icon" />
              </Box>
              <Box flex={1}>
                <Flex
                  alignItems={responsive('auto', 'flex-end')}
                  justifyContent={responsive('center', 'space-between')}
                  flexDirection={responsive('column-reverse', 'row')}
                >
                  <Box flex="1">
                    <Flex alignItems="center">
                      {isMobile && <Box fontSize="1.125em" lineHeight="1.5">{data.startDate.replace(/[-]/g, '/')} |</Box>}
                      <Box pl={responsive('0.5em', 0)}>{data.orginization.shortName}</Box>
                    </Flex>
                    <Text.H3
                      fontWeight="bold"
                      color="darkPurple"
                      fontSize={responsive('1.125em', '1.375em')}
                    >{data.subject}</Text.H3>
                  </Box>
                  {!isMobile && <Box ml="0.5em" fontSize="1.25em">{data.startDate.replace(/[-]/g, '/')}</Box>}
                </Flex>
              </Box>
            </Flex>
            <Box
              mt="1.375em"
              px={responsive('1em', '6.875em')}
              py={responsive('2.125em', '4.5em')}
              borderBottom='2px solid'
              borderTop='2px solid'
              borderColor="lightPurple"
              position="relative"
            >
              <Text fontSize="1.125em" lineHeight="1.75">{data.content}</Text>
              <ImageGallery
                // px={responsive(0, '2em')}
                mt="2em"
                mb="4em"
                images={imgKeys.map(k => data[k]).filter(Boolean)}
              />
              <Box
                position="absolute"
                width={responsive('5.75em', 'auto')}
                right={responsive(0, '-0.5em')}
                left={isMobile && '50%'}
                bottom="0"
                transform={responsive('translate(-50%, 75%)', 'translateY(20%)')}
              >
                <Image src={deco} alt="文件的Icon" />
              </Box>
            </Box>
            <Box mt={responsive('7.875em', '2.625em')} textAlign="center">
              <Button as={(props) => <Link color="white" {...props} />} to="/news" fontSize="1.5em">回上一頁</Button>
            </Box>
          </ContentLayout>
        ) : <Redirect to="/" />
      )}
    </Data>
  )
}

export default SingleNews
